
import { Component, Vue, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Register as RegisterModel } from '@/models/auth/register.class';
import { AddressData as AddressDataModel } from '@/models/auth/address.class';
import { Login } from '../models/auth/login.class';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';

const authModule = namespace('auth');

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Register extends Mixins(UseCurrentLocaleMixin) {
  @authModule.Action('register')
  register!: (user: RegisterModel) => Promise<boolean | string>;

  @authModule.Action('login')
  login!: (login: Login) => Promise<boolean | string>;

  user: RegisterModel = new RegisterModel();
  addressData: AddressDataModel = new AddressDataModel();
  errorTranslationKey = '';
  showPassword = false;
  showLoading = false;

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async onSubmit() {
    this.showLoading = true;
    this.errorTranslationKey = '';

    this.user.language = this.$i18n.locale;

    const customerName = this.addressData.name;

    const registerResponse = await this.register({
      ...this.user,
      addressData: {
        ...this.addressData,
        name: customerName?.length ? customerName : `${this.user.firstName} ${this.user.lastName}`,
      },
    });

    if (typeof registerResponse === 'string') {
      this.errorTranslationKey = registerResponse;
      this.resetForm();
    } else {
      await this.login({
        email: this.user.email,
        password: this.user.password,
      });

      const redirectPath = this.$route.query.redirect as string;
      if (redirectPath) {
        this.$router.push(redirectPath);
      } else {
        this.$router.push(`${this.localeBaseUrlPath}/`);
      }
    }

    this.showLoading = false;
  }

  resetForm() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { password, confirmPassword, ...rest } = this.user;
    this.user = { ...new RegisterModel(), ...rest };
    this.$refs.observer.reset();
  }
  trimInput(value: string) {
    this.user.phone = value.split(' ').join('');
  }
}
